import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';

import { ChartBarIcon, FolderOpenIcon, HomeIcon, UserGroupIcon, UsersIcon, XMarkIcon, UserIcon, Squares2X2Icon, Cog6ToothIcon, Bars3Icon } from "@heroicons/react/24/outline";

import { Link, useNavigate, useLocation } from 'react-router-dom';
import { themeChange } from 'theme-change';

// import SuccesNotification from '../../Fragments/Notification/SuccessNoitification';
import SuccessNotification from '../Notification/SuccessNoitification'

const navigation = [
    { name: 'Dashboard', href: '/dashboard', icon: HomeIcon },
    { name: 'Team', href: '/team', icon: UserGroupIcon },
    { name: 'Projects', href: '/projects', icon: FolderOpenIcon },
    { name: 'User', href: '/user', icon: UsersIcon },
    { name: 'Event', href: '/event', icon: ChartBarIcon }
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Sidebar({ slot }) {
    const { dataUser } = useSelector((state) => state.AuthReducer);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [notificationVisible, setNotificationVisible] = useState(false);

    const closeNotification = () => {
        setTimeout(() => {
            setNotificationVisible(false);
        }, 1000);
    };

    const userNavigation = [
        { name: "Your Profile", href: "/profile" },
        { name: "Settings", href: "#" },
        // { name: "Sign out", href: "/login" },
    ];

    const Logout = () => {
        localStorage.clear('jwt_access_token');
        setNotificationVisible(true);
        setTimeout(() => {
            dispatch({ type: 'Logout' });
            navigate('/login');
        }, 2000);
    };

    const [currentTheme, setCurrentTheme] = useState(localStorage.getItem('theme'));
    useEffect(() => {
        themeChange(false);
        if (currentTheme === null) {
            window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
                ? setCurrentTheme('dark')
                : setCurrentTheme('light');
        }
    }, []);

        const [changeSidebarWidth, setSidebarWidth] = useState("w-20");
        const textRef = useRef(null);

        const handleSidebar = () => {
            setSidebarWidth((prevWidth) => (prevWidth === "w-20" ? "w-64" : "w-20"));
        };

        useEffect(() => {
            if (textRef.current) {
                if (changeSidebarWidth === 'w-20') {
                    textRef.current.classList.remove('hidden');
                } else {
                    textRef.current.classList.add('hidden');
                }
            }
        }, [changeSidebarWidth]);
    

    // console.log(dataUser)

    const pathname = location.pathname;
    const pathArray = pathname.split('/');
    const paramValue = pathArray[pathArray.length - 1];

    return (
        <div>
            <SuccessNotification
                show={notificationVisible}
                onClose={closeNotification}
                message="Anda telah Logout!"
                description="Sampai Jumpa Kembali!"
            />

            {/* Mobile Menu */}
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-40 flex md:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex flex-col flex-1 w-auto max-w-xs pt-5 pb-4 bg-slate-100 dark:bg-slate-700">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 pt-2 -mr-12">
                                    <button
                                        type="button"
                                        className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XMarkIcon className="w-6 h-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex items-start justify-start flex-shrink-0 gap-3 px-4">
                                <img className="w-auto h-8 rounded-full" src="./kelbuk.png" alt="Kelas Terbuka" />
                                <span className="text-2xl font-medium tracking-wider text-slate-500 dark:text-slate-600 ">
                                    Kelas Terbuka
                                </span>
                            </div>
                            <div className="flex-1 h-0 mt-5 overflow-y-auto ">
                                <nav className="px-2 space-y-1">
                                    {navigation.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            className={classNames(
                                                item.current
                                                    ? 'bg-orange-500 text-white'
                                                    : 'text-slate-500 hover:bg-slate-500',
                                                'group flex items-center px-2 py-2 text-base font-medium rounded-md tracking-wider'
                                            )}
                                        >
                                            <item.icon
                                                className="flex-shrink-0 w-6 h-6 mr-4 text-slate-700 dark:text-slate-300"
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </Link>
                                    ))}
                                </nav>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
                </Dialog>
            </Transition.Root>

            <div className="flex flex-col md:flex-row">
                {/* Sidebar */}
                <div className={`max-md:hidden min-h-screen grid grid-cols-1 ${changeSidebarWidth === "w-20" ? "w-64" : "w-20"} group transition-all ease-in-out delay-100 duration-300 md:inset-y-0`}>
                    <div className="flex flex-col flex-grow overflow-y-auto z-100">
                        <Menu as="div" className="relative mx-5">
                            <Menu.Button className="flex flex-row items-center justify-center h-16 gap-3 mt-3 rounded-lg">
                                <img className={`rounded-full max-h-14 max-w-14`} src="/kelbukrmbg.png" alt="kelbuk" />
                                <h1 ref={textRef} id="text" className="text-lg font-bold text-green-500">Kelas Terbuka</h1>
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="absolute -right-0 z-[99999] w-48 py-1 mt-2 origin-top-right bg-gray-50 dark:bg-slate-700 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {userNavigation.map((item) => (
                                        <Menu.Item key={item.name}>
                                            {({ active }) => (
                                                <Link
                                                    to={item.href}
                                                    className={classNames(
                                                        active ? "bg-slate-600/50 text-slate-400" : "text-slate-600 dark:text-slate-300 hover:bg-slate-700/50",
                                                        "block px-4 py-2 text-sm text-slate-600 dark:text-slate-300"
                                                    )}>
                                                    {item.name}
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    ))}
                                </Menu.Items>
                            </Transition>
                        </Menu>
                        <nav className="flex flex-col justify-between h-full">
                            <div className="flex flex-col mx-3 mt-5 ">
                                <nav className="flex-1 px-2 pb-4 space-y-1" >
                                    {navigation.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            className={classNames(
                                                location.pathname === item.href ? "bg-gray-100 dark:bg-gray-700/50 " : " hover:bg-gray-100 dark:hover:bg-gray-900/50 ",
                                                `group flex items-center px-2 py-2 text-sm font-medium rounded-md tracking-wider`
                                            )} >
                                            <item.icon className="flex-shrink-0 w-6 h-6 mr-3 " aria-hidden="true" />
                                            <span className="truncate ">{item.name}</span>
                                        </Link>
                                    ))}
                                </nav>
                            </div>
                            <div className="flex mx-3 mt-5 flex-col ">
                                <nav className="flex-1 px-2 pb-4 space-y-1" >
                                    <button onClick={handleSidebar} className="flex gap-3 text-sm group rounded-md items-center px-2 py-2 font-medium tracking-wider hover:bg-gray-100 dark:hover:bg-gray-900/50">
                                        <Bars3Icon className="w-6 h-6 flex-shrink-0" />
                                        <span className="truncate ">Sidebar</span>
                                    </button>
                                    <button className="flex gap-3 text-sm group items-center px-2 py-2 rounded-md font-medium tracking-wider hover:bg-gray-100 dark:hover:bg-gray-900/50">
                                        <Cog6ToothIcon className="w-6 h-6 flex-shrink-0" />
                                        <span className="truncate ">Setting</span>
                                    </button>
                                    <button className="flex gap-3 text-sm group items-center px-2 py-2 rounded-md font-medium tracking-wider">
                                        <img src={dataUser.data.images} className="max-w-14 max-h-14 rounded-full" alt="User" />
                                        <div className="flex flex-col gap-1 text-left">
                                            <h1 className="truncate font-bold">{dataUser.data.fullname}</h1>
                                            <span className="text-slate-500">{dataUser.data.role}</span>
                                        </div>
                                    </button>
                                </nav>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="flex flex-col flex-1 ">
                    <main>
                        <div className="mx-5 mt-3">
                            <div className="">{slot}</div>
                        </div>
                    </main>
                {/* Navbar Menu Mobile */}
                    <navbar className="fixed bottom-0 w-full h-auto px-5 py-2 bg-slate-300 dark:bg-slate-800 md:hidden">
                        <div className="flex justify-around">
                            <Link to={"/dashboard"} className="flex flex-col items-center justify-center gap-1">
                                <HomeIcon className="w-8 h-8 rounded-full" /> <span>Home</span>
                            </Link>
                            <div className="dropdown dropdown-top ">
                                <div tabIndex={0} role="button" className="flex flex-col items-center justify-center gap-1">
                                    <Squares2X2Icon className="w-8 h-8 rounded-full" /> <span>Menu</span></div>
                                <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                                    {navigation.filter((item) => item.name !== "Dashboard").map((item) => (
                                        <li
                                            key={item.name}
                                        >
                                            <a href={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-orange-500 text-white'
                                                        : 'text-slate-500 hover:bg-slate-500',
                                                    'group flex flex-row items-center justify-left  px-2 py-2 tracking-wider'
                                                )}>
                                                <item.icon
                                                    className="flex-shrink-0 w-6 h-6 text-slate-700 dark:text-slate-300"
                                                    aria-hidden="true"
                                                />
                                                <span> {item.name}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <Link to={"/profile"} className="flex flex-col items-center justify-center gap-1">
                                <UserIcon className="w-8 h-8 rounded-full" /> <span>Profile</span>
                            </Link>
                        </div>
                    </navbar>
                </div>
            </div>
        </div>
    );
}
