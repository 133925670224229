import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedPages from './config/ProtectedKelbuk';
import React, { lazy } from 'react';

const Page = lazy(() => import('./pages/Page'));
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));

const Dashboard = lazy(() => import('./pages/AdminPages/Dasboard'));
const Team = lazy(() => import('./pages/AdminPages/Team'));
const Projects = lazy(() => import('./pages/AdminPages/Projects'));
const User = lazy(() => import('./pages/AdminPages/User'));
const Event = lazy(() => import('./pages/AdminPages/Event'));
const Profile = lazy(() => import('./pages/AdminPages/Profile'));
const NotFound = lazy(() => import('./pages/NotFound'));

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Page />} />

        <Route path="/profile" element={<ProtectedPages slot={<Profile />} />} />
        <Route path="/dashboard" element={<ProtectedPages slot={<Dashboard />} />} />
        <Route path="/team" element={<ProtectedPages slot={<Team />} />} />
        <Route path="/projects" element={<ProtectedPages slot={<Projects />} />} />
        <Route path="/user" element={<ProtectedPages slot={<User />} />} />
        <Route path="/event" element={<ProtectedPages slot={<Event />} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}