import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getDetailById } from '../API/Auth/Auth';
import Sidebar from '../components/Sidebar';
import { SetLoginData } from './redux/action/authAction';

function ProtectedPages({ slot }) {
    const { dataUser, isLoged } = useSelector((state) => state.AuthReducer);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const fetchData = async () => {
        try {
            const DetailUser = await getDetailById();
            dispatch(SetLoginData(DetailUser.data));
            localStorage.setItem('jwt_access_token', DetailUser.data.accessToken);
        } catch (e) {
            localStorage.clear('jwt_access_token');
            if (dataUser.accessToken) {
                alert('Token Expired');
                dispatch({ type: 'Logout' });
            } else {
                dispatch({ type: 'Logout' });
            }
            navigate('/login');
        }
    };

    const checkTokenExpiration = async () => {
        const currentTime = Math.floor(Date.now() / 1000);
        if (dataUser && dataUser.exp && dataUser.exp < currentTime) {
            localStorage.clear('jwt_access_token');
            alert('Token Expired');
            dispatch({ type: 'Logout' });
            return navigate('/login');
        }
    };

    useEffect(() => {
        checkTokenExpiration();
        if (!isLoged) {
            try {
                fetchData();
            } catch (e) {
                return navigate('/login');
            }
        }
    }, [dataUser, dispatch, navigate]);

    if (isLoged) {
        return <Sidebar slot={slot} />;
    }

    // return <>{isLoged ? <Sidebar slot={slot} /> : ""}</>;
}

export default ProtectedPages;
