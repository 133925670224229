import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducer/reducer';

// - Load state from cookie
const loadState = () => {
  try {
    const serializedState = getCookie('reduxState');
    if (serializedState === undefined) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// - Save state to cookie
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    setCookie('reduxState', serializedState, 0.8); // Expires in 1 day. kalo 0.5 ya setengah hari
  } catch (err) {
    console.error(err);
  }
};

// - Helper functions for cookies
const setCookie = (name, value, days) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};

// - Cookie function for web
const getCookie = (name) => {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    if (cookie.trim().startsWith(cookieName)) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return undefined;
};

const persistedState = loadState();

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //mode dev

// const store = createStore(reducer, persistedState, composeEnhancers(applyMiddleware(thunk))); //mode dev
const store = createStore(reducer, persistedState, applyMiddleware(thunk));

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
