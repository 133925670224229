import axios from 'axios';
import { useDispatch } from 'react-redux';
import store from '../config/redux/store';

const apiKelbuk = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
  headers: {
    Authorization: localStorage.getItem('jwt_access_token'),
    'Content-Type': 'multipart/form-data',
  },
  crossDomain: true,
});

apiKelbuk.interceptors.request.use(
  (config) => {
    if (axios.isCancel(config)) {
      return console.log(config);
    }
    const token = localStorage.getItem('jwt_access_token');
    config.headers['Authorization'] = 'Bearer ' + token;

    return config;
  },
  (error) => {
    const dispatch = useDispatch();
    if (error.response && error.response.status === 401) {
      dispatch({ type: 'Logout' });
    }
    Promise.reject(error);
  }
);

apiKelbuk.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch({ type: 'Logout' });
    }
    return Promise.reject(error);
  }
);

export default apiKelbuk;
