const initialStateHome = {
  dataUser: {},
  isLoged: false
};

const AuthReducer = (state = initialStateHome, action) => {
  if (action.type === 'Login') {
    return {
      ...state,
      dataUser: action.payload,
      isLoged: true
    };
  }

  if (action.type === 'Logout') {
    localStorage.clear('jwt_access_token');
    return {
      ...state,
      isLoged: false,
      dataUser: {}
    };
  }

  return state;
};

export default AuthReducer;
