// import { FormLogin } from "../../../API/Auth/Auth";
export const SetLoginData = (userdata) => async (dispatch) => {
  try {
    dispatch({ type: 'Login', payload: userdata });
  } catch (error) {
    return error;
  }
};

export const SetRegisterData = (userdata) => async (dispatch) => {
  try {
    dispatch({ type: 'Register', payload: userdata });
  } catch (error) {
    return error;
  }
};
