// import all API to be a variable
import apiKelbuk from '../kelbuk-api';

// @ Login & Register for a user
export const FormLogin = (form) => apiKelbuk.post('/login', form);
export const FormRegister = (form) => apiKelbuk.post('/register', form);

// ===== Create & Read Data
export const getDetailById = () => apiKelbuk.get('/detailbytoken');
export const getAllUserData = (page, perPage) =>
  apiKelbuk.get(`/getalluser?page=${page ?? 1}&perPage=${perPage ?? 5}`);
export const getAllEvent = (page, perPage) =>
  apiKelbuk.get(`/getallevent?page=${page ?? 1}&perPage=${perPage ?? 5}`);
export const getAllEv = () => apiKelbuk.get(`/getallevent`);
export const getAllModerator = (page, perPage) =>
  apiKelbuk.get(`/getallmoderator?page=${page ?? 1}&perPage=${perPage ?? 5}`);
export const getAllMod = () => apiKelbuk.get('/getallmoderator/');

// 1. @ User (Create Read Update & Delete)
export const addNewUserData = (form) => apiKelbuk.post('/register', form);
export const editDatauser = (id, form) =>
  apiKelbuk.put(`/updateuser/${id}`, form);
export const deleteUser = (id) => apiKelbuk.delete(`/deleteUser/${id}`);

// 2. @ Moderator (Create Read Update & Delete)
export const addNewModeratorData = (form) =>
  apiKelbuk.post('/registermoderator', form);
export const editModeratorData = (id, form) =>
  apiKelbuk.put(`/updatemoderator/${id}`, form);
export const deleteModeratorData = (id) =>
  apiKelbuk.delete(`./deletemoderator/${id}`);
export const updateModerator = (id, data) =>
  apiKelbuk.put(`/updatemoderator/${id}`, data);

// 3. @ Event (Create Read Update & Delete)
export const addNewEventData = (form) => apiKelbuk.post('/registerevent', form);
export const editEventData = (id, form) =>
  apiKelbuk.put(`/updateevent/${id}`, form);
export const deleteEventData = (id) => apiKelbuk.delete(`./deletevent/${id}`);
